import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// sample page routing
const Homepage = Loadable(lazy(() => import('views/homepage')));

const PatientNewPage = Loadable(lazy(() => import('views/patientNew')));
const PatientList = Loadable(lazy(() => import('views/patientList')));
const PatientEdit = Loadable(lazy(() => import('views/patientEdit')));
const UserNew = Loadable(lazy(() => import('views/userManagement/userNew')));
const VitalsAlerts = Loadable(lazy(() => import('views/vitalsAlerts')));
const Reports = Loadable(lazy(() => import('views/reports')));
const ClinicTasks = Loadable(lazy(() => import('views/task/clinicTasksOuterWrapper')));
const ProtocolTasks = Loadable(lazy(() => import('views/task/protocolTasksOuterWrapper')));
const UserManagement = Loadable(lazy(() => import('views/userManagement')));
const UserDetail = Loadable(lazy(() => import('views/userManagement/userDetails')));
const ClinicDetails = Loadable(lazy(() => import('views/clinicSettings/clinicDetails')));
const ProtocolsTemplateEditor = Loadable(lazy(() => import('views/protocolsTemplate/protocolsTemplateEditor')));
const ProtocolsTemplateList = Loadable(lazy(() => import('views/protocolsTemplate/protocolsTemplateList')));
const CustomProgrammaticTaskList = Loadable(lazy(() => import('views/customProgrammaticTasks/list')));
const CustomProgrammaticTaskEditor = Loadable(lazy(() => import('views/customProgrammaticTasks/instance')));
const ProgrammaticTaskPreview = Loadable(lazy(() => import('views/clinicSettings/programmaticTaskPreview')));
const ProgrammaticTasks = Loadable(lazy(() => import('views/clinicSettings/programmaticTasks')));
const OrganizationTeamsManagement = Loadable(lazy(() => import('views/teams/organizationTeamManagement')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <Homepage />
        },
        {
            path: '/clinic/:clinicId/patients',
            element: <PatientList />
        },
        {
            path: '/clinic/:clinicId/patient/new',
            element: <PatientNewPage />
        },
        {
            path: '/clinic/:clinicId/patient/:patientId/edit/:tab/:subTab',
            element: <PatientEdit />
        },
        {
            path: '/user/new',
            element: <UserNew />
        },
        {
            path: '/clinic/:clinicId/vitals/alerts',
            element: <VitalsAlerts />
        },
        {
            path: '/clinic/:clinicId/reports',
            element: <Reports />
        },
        {
            path: '/clinic/:clinicId/tasks',
            element: <ClinicTasks />
        },
        {
            path: '/clinic/:clinicId/tasks-protocols',
            element: <ProtocolTasks />
        },
        {
            path: '/user/:userId/detail',
            element: <UserDetail />
        },
        {
            path: '/users',
            element: <UserManagement />
        },
        {
            path: '/clinic/:clinicId/details',
            element: <ClinicDetails />
        },
        {
            path: '/protocols/template/:templateId/edit',
            element: <ProtocolsTemplateEditor />
        },
        {
            path: '/protocols/templates',
            element: <ProtocolsTemplateList />
        },
        {
            path: '/custom-programmatic-task/list',
            element: <CustomProgrammaticTaskList />
        },
        {
            path: '/custom-programmatic-task/:instanceId/edit',
            element: <CustomProgrammaticTaskEditor />
        },
        {
            path: '/clinic/:clinicId/programmatic-task-preview/:previewRunId',
            element: <ProgrammaticTaskPreview />
        },
        {
            path: '/clinic/:clinicId/programmatic-tasks',
            element: <ProgrammaticTasks />
        },
        {
            path: '/organization-teams-management',
            element: <OrganizationTeamsManagement />
        }
    ]
};

export default MainRoutes;
