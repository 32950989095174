// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { dispatch } from '../index';
import * as userAPI from 'api/user';

const initialState = {
    accessPermissionsList: {
        error: false,
        loaded: false,
        data: []
    },
    activeOrgMembersList: {
        error: false,
        loaded: false,
        data: []
    },
    userDetail: {
        error: false,
        loaded: false,
        data: {
            id: 0,
            firstName: '',
            lastName: '',
            email: '',
            disabled: false,
            orgGrants: [],
            clinicGrants: {},
            providerClinicMapping: [],
            // null if not a provider account
            providerId: null,
            // if has the provider clinic matching for selected clinic
            assignedToClinic: true,
            primaryCarePhysician: false,
            specialist: false,
            title: '',
            npi: ''
        }
    }
};

const slice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setUserPermissionsListError(state) {
            state.accessPermissionsList.error = true;
            state.accessPermissionsList.loaded = true;
        },
        setUserPermissionsList(state, action) {
            state.accessPermissionsList.error = false;
            state.accessPermissionsList.loaded = true;
            state.accessPermissionsList.data = action.payload;
        },
        setActiveOrgMembersListError(state) {
            state.activeOrgMembersList.error = true;
            state.activeOrgMembersList.loaded = true;
        },
        setActiveOrgMembersList(state, action) {
            state.activeOrgMembersList.error = false;
            state.activeOrgMembersList.loaded = true;
            state.activeOrgMembersList.data = action.payload;
        },
        setUserDetail(state, action) {
            state.userDetail.error = false;
            state.userDetail.loaded = true;
            state.userDetail.data = action.payload;
        },
        setUserDetailError(state, action) {
            state.userDetail.error = action.payload;
            state.userDetail.loaded = true;
        },
        resetUserDetailState(state) {
            state.userDetail = initialState.userDetail;
        },
        resetActiveOrgMembersListState(state) {
            state.activeOrgMembersList = initialState.activeOrgMembersList;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getUserPermissionList() {
    return async () => {
        try {
            const response = await userAPI.fetchUsersPermissionList();
            dispatch(slice.actions.setUserPermissionsList(response.data.users));
        } catch (error) {
            dispatch(slice.actions.setUserPermissionsListError(error));
        }
    };
}

export function getUserDetail(userId) {
    return async () => {
        try {
            const response = await userAPI.getUserDetail(userId);
            dispatch(slice.actions.setUserDetail(response.data.user));
        } catch (error) {
            dispatch(slice.actions.setUserDetailError(error));
        }
    };
}

export function resetUserDetail() {
    dispatch(slice.actions.resetUserDetailState());
}

export function resetActiveOrgMembersList() {
    dispatch(slice.actions.resetActiveOrgMembersListState());
}

export function getActiveOrgMembersList() {
    return async () => {
        try {
            const response = await userAPI.fetchActiveOrgMembersList();
            dispatch(slice.actions.setActiveOrgMembersList(response.data.users));
        } catch (error) {
            dispatch(slice.actions.setActiveOrgMembersListError(error));
        }
    };
}
