// assets
import {
    IconUserExclamation,
    IconReport,
    IconUsers,
    IconHeartRateMonitor,
    IconListCheck,
    IconSettings,
    IconCheckupList,
    IconServerBolt,
    IconFriends
} from '@tabler/icons';

function getPatientMenuConfig(clinicId, taskCountClinic, taskCountProtocol, vitalsAlertCount) {
    const taskItem = [
        {
            id: 'tasks-view',
            title: <span id="tasks-view">Clinic Tasks</span>,
            type: 'item',
            url: `/clinic/${clinicId}/tasks?initialAssignedToFilter=me`,
            icon: IconListCheck,
            breadcrumbs: false
        }
    ];
    if (taskCountClinic > 0) {
        taskItem[0].chip = {
            label: taskCountClinic,
            size: 'small',
            color: 'error',
            tooltip: 'You have clinic tasks due today or tasks past due.'
        };
    }

    const protocolTaskItem = [
        {
            id: 'protocols-tasks-view',
            title: <span id="protocols-tasks-view"> Protocols</span>,
            type: 'item',
            url: `/clinic/${clinicId}/tasks-protocols?initialAssignedToFilter=me`,
            icon: IconListCheck,
            breadcrumbs: false
        }
    ];
    if (taskCountProtocol > 0) {
        protocolTaskItem[0].chip = {
            label: taskCountProtocol,
            size: 'small',
            color: 'warning',
            tooltip: 'You have protocol tasks due today or tasks past due.'
        };
    }

    const vitalsAlertItem = [
        {
            id: 'vital-alerts',
            title: <span id="vital-alerts"> Vital Alerts</span>,
            type: 'item',
            url: `/clinic/${clinicId}/vitals/alerts`,
            icon: IconHeartRateMonitor,
            breadcrumbs: false
        }
    ];

    if (vitalsAlertCount > 0) {
        vitalsAlertItem[0].chip = {
            label: vitalsAlertCount,
            size: 'small',
            color: 'error',
            tooltip: 'There are open vitals alerts for this clinic.'
        };
    }

    return {
        id: 'patient',
        title: <span id="patient"> Patients</span>,
        type: 'group',
        children: [
            {
                id: 'patients-list',
                title: <span id="patients">Patients</span>,
                type: 'item',
                url: `/clinic/${clinicId}/patients`,
                icon: IconUsers,
                breadcrumbs: false
            },
            ...vitalsAlertItem,
            ...taskItem,
            ...protocolTaskItem
        ]
    };
}

function getClinicMenuConfig(clinicId, isClinicManagerOrHigher) {
    const clinicMenu = [];

    if (isClinicManagerOrHigher) {
        clinicMenu.push({
            id: 'reports-view',
            title: <span id="reports"> Reports </span>,
            type: 'item',
            url: `/clinic/${clinicId}/reports`,
            icon: IconReport,
            breadcrumbs: false
        });

        clinicMenu.push({
            id: 'clinic-details',
            title: <span id="clinic-details-title"> Clinic Details </span>,
            type: 'item',
            url: `/clinic/${clinicId}/details`,
            icon: IconSettings,
            breadcrumbs: false
        });

        clinicMenu.push({
            id: 'programmatic-tasks',
            title: <span id="programmatic-tasks-title"> Programmatic Tasks </span>,
            type: 'item',
            url: `/clinic/${clinicId}/programmatic-tasks`,
            icon: IconServerBolt,
            breadcrumbs: false
        });
    }

    return {
        id: 'clinic-settings',
        title: <span id="clinicsettings"> Clinic Settings</span>,
        type: 'group',
        children: clinicMenu
    };
}

function getOrgMenuConfig(isOrgAdmin) {
    const orgMenu = [];

    if (isOrgAdmin) {
        orgMenu.push({
            id: 'protocols-creator-view',
            title: <span id="protocols-creator"> Protocol Templates </span>,
            type: 'item',
            url: `/protocols/templates`,
            icon: IconCheckupList,
            breadcrumbs: false
        });
        orgMenu.push({
            id: 'user-management-v2',
            title: <span id="user"> Users </span>,
            type: 'item',
            url: '/users',
            icon: IconUserExclamation,
            breadcrumbs: false
        });
        orgMenu.push({
            id: 'organization-teams',
            title: <span id="organizaiton-teams-title"> Teams Management </span>,
            type: 'item',
            url: '/organization-teams-management',
            icon: IconFriends,
            breadcrumbs: false
        });
    }
    return {
        id: 'org-settings',
        title: <span id="orgsettings"> Org Settings</span>,
        type: 'group',
        children: orgMenu
    };
}

// eslint-disable-next-line import/prefer-default-export
export function getMenuItems(clinicId, taskCountClinic, taskCountProtocol, isOrgAdmin, isClinicManagerOrHigher, vitalsAlertCount) {
    const patientItems = getPatientMenuConfig(clinicId, taskCountClinic, taskCountProtocol, vitalsAlertCount);
    const clinicItems = getClinicMenuConfig(clinicId, isClinicManagerOrHigher);
    const orgItems = getOrgMenuConfig(isOrgAdmin);

    const menuItems = [];
    if (patientItems?.children?.length > 0) {
        menuItems.push(patientItems);
    }
    if (clinicItems?.children?.length > 0) {
        menuItems.push(clinicItems);
    }
    if (orgItems?.children?.length > 0) {
        menuItems.push(orgItems);
    }

    return menuItems;
}
