import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../index';

import * as teamsAPI from 'api/teams';

const initialState = {
    list: {
        error: false,
        loaded: false,
        data: []
    }
};

const slice = createSlice({
    name: 'teams',
    initialState,
    reducers: {
        setTeamsListError(state) {
            state.list.error = true;
            state.list.loaded = true;
        },
        setTeamsList(state, action) {
            state.list.error = false;
            state.list.loaded = true;
            state.list.data = action.payload;
        },
        resetListState(state) {
            state.list = initialState.list;
        }
    }
});

export default slice.reducer;

export function resetListState() {
    dispatch(slice.actions.resetListState());
}
export function getTeamsList() {
    return async () => {
        try {
            const response = await teamsAPI.getTeamsList();
            dispatch(slice.actions.setTeamsList(response.data.teams));
        } catch (error) {
            dispatch(slice.actions.setTeamsListError(error));
        }
    };
}
